import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Subject, catchError, tap, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  CreatePassword,
  ForgotPassword,
  GetOrganizationDataResponse,
  ILoginData,
  ILoginForm,
  LoginResponse,
  LoginUserInfo,
  UpdateProfile,
} from './authServiceInterfaces';
import { Router } from '@angular/router';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public url = `${environment.apiBaseUrl}`;
  private readonly createPasswordUrl = `${this.url}/auth-service/auth/create-password`;
  private readonly _loginUrl = `${this.url}/auth-service/auth/login`;
  private readonly _resetPasswordUrl = `${this.url}/auth-service/auth/request-reset-password`;
  private readonly _changePasswordUrl = `${this.url}/auth-service/auth/reset-password`;
  private readonly _getStartedUrl = `${environment.apiBaseUrl}/user-service/application`;
  private readonly userSubject: BehaviorSubject<LoginUserInfo | null>;
  public user: LoginUserInfo | null = null;
  private readonly firstNameSubject = new BehaviorSubject<string>('');
  private readonly lastNameSubject = new BehaviorSubject<string>('');
  private readonly telephoneNumber = new BehaviorSubject<string>('');
  private readonly imageSubject = new BehaviorSubject<string>('');
  public organisationId: string = '';

  firstName$ = this.firstNameSubject.asObservable();
  lastName$ = this.lastNameSubject.asObservable();
  telephoneNumber$ = this.telephoneNumber.asObservable();
  image$ = this.imageSubject.asObservable();

  constructor(
    private readonly _http: HttpClient,
    private readonly router: Router,
    private readonly notificationService: NotificationsService
  ) {
    const userValue = this.getLoginUser();

    this.userSubject = new BehaviorSubject(userValue ?? null);
  }

  setFirstName(firstName: string) {
    this.firstNameSubject.next(firstName);
  }

  setLastName(lastName: string) {
    this.lastNameSubject.next(lastName);
  }

  setImage(image: string) {
    this.imageSubject.next(image);
  }

  getFirstName() {
    return this.firstNameSubject.asObservable();
  }
  getImage() {
    return this.imageSubject.value;
  }
  setTelephoneNumber(telephoneNumber: string) {
    this.telephoneNumber.next(telephoneNumber);
  }

  setLoginUser(user: LoginUserInfo | null) {
    this.user = user;
    this.userSubject.next(user);
  }

  getLoginUser() {
    return this.user;
  }
  getProfile() {
    return this.userSubject.value;
  }
  private readonly _refreshrequired = new Subject<void>();

  get RefreshRequired() {
    return this._refreshrequired;
  }
  createPassword(createPasswordData: CreatePassword) {
    return this._http
      .post(this.createPasswordUrl, createPasswordData)
      .pipe(catchError(this.errorHandler));
  }

  loginUser(userData: ILoginForm) {
    return this._http
      .post<LoginResponse>(this._loginUrl, userData)
      .pipe(catchError(this.errorHandler));
  }

  getSingleUser(organisationId: string, userId: string) {
    this.organisationId = organisationId;
    return this._http
      .get<ILoginData>(
        `${environment.apiBaseUrl}/user-service/users/${organisationId}/${userId}`
      )
      .pipe(catchError(this.errorHandler));
  }

  forgotPassword(forgotPasswordData: ForgotPassword) {
    return this._http
      .post(this._resetPasswordUrl, forgotPasswordData)
      .pipe(catchError(this.errorHandler));
  }

  resetPassword(userData: ForgotPassword) {
    return this._http
      .post(this._resetPasswordUrl, userData)
      .pipe(catchError(this.errorHandler));
  }

  changePassword(userData: {
    token: string;
    password: string;
    confirmPassword: string;
  }) {
    return this._http
      .post(this._changePasswordUrl, userData)
      .pipe(catchError(this.errorHandler));
  }

  getToken() {
    return sessionStorage.getItem('access_token');
  }

  logOut() {
    this.setLoginUser(null);
    this.notificationService.notificationsCount.next(0);
    sessionStorage.clear();
    setTimeout(() => {
      window.location.reload();
    }, 100);
    this.router.navigate(['/login']);
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(() => error);
  }

  isAuthenticated(): boolean {
    return !!sessionStorage.getItem('access_token');
  }

  getStarted(getStartedData: {
    email: string;
    company_name: string;
    full_name: string;
    position: string;
    industry: string;
    company_size: string;
  }) {
    return this._http.post(this._getStartedUrl, getStartedData).pipe(
      tap(() => {
        this.RefreshRequired.next();
      }),
      catchError((error) => {
        return this.errorHandler(error);
      })
    );
  }

  getUserProfile(organisationId: string) {
    return this._http.get<ILoginData>(
      `${environment.apiBaseUrl}/user-service/users/${organisationId}/my-profile`
    );
  }

  userProfileChangePassword(passwordData: {
    currentPassword: string;
    newPassword: string;
  }) {
    return this._http.post(
      `${environment.apiBaseUrl}/auth-service/auth/change-password`,
      passwordData
    );
  }

  updateUserProfile(organisationId: string, userUpdatedInfo: UpdateProfile) {
    return this._http.put<ILoginData>(
      `${environment.apiBaseUrl}/user-service/users/${organisationId}/my-profile`,
      userUpdatedInfo
    );
  }
  getOrganizationData(organisationId: string) {
    return this._http.get<GetOrganizationDataResponse>(
      `${environment.apiBaseUrl}/user-service/admin/${organisationId}`
    );
  }

  deleteProfileImage(organisationId: string) {
    return this._http.delete<ILoginData>(
      `${environment.apiBaseUrl}/user-service/users/${organisationId}/profile-image`
    );
  }

  validateCreatePasswordLink() {
    return this._http.get(`
      ${environment.apiBaseUrl}/auth-service/auth/verify/create-password-link`);
  }

  validateResetPasswordLink() {
    return this._http.get(`
      ${environment.apiBaseUrl}/auth-service/auth/verify/reset-password-link`);
  }

  verifyUser() {
    return this._http.get(
      `${environment.apiBaseUrl}/auth-service/auth/verify/user`
    );
  }
}
